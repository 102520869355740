<template>
  <div>
    <ProductBarcode />
  </div>
</template>

<script>
import ProductBarcode from '@/components/ProductBarcode.vue'

export default {
  components: { ProductBarcode },
  props: ['info']
}
</script>
