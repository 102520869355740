<template>
  <div>
    <FixedNavbar :info="info" v-if="$store.state.mobileWidth > $fnc.getWidth() && !noNavBar" />
    <div class="footerContainer">
      <footer class="container" v-if="info.companyInfo">
        <div class="ft">
          <p>Kategoriler</p>
          <router-link v-for="item in info.menu" :key="item.id" :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">{{ item.title }}</router-link>
        </div>
        <div class="ft">
          <p>Kurumsal</p>
          <router-link v-for="item in info.kurumsal" :key="item.id" :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: item.cat_id }}">{{ item.title }}</router-link>
        </div>
        <div class="ftr">
          <p>Bizi Takip Edin</p>
          <a v-for="item in info.socials" :key="item.id" rel="noopener" :href="item.paramValue" target="_blank">{{ item.paramTitle }}</a>
        </div>
        <div class="ftr">
          <p>İletişim</p>
          <router-link :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_ADRES }}</router-link>
          <router-link :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_PHONE }}</router-link>
        </div>
      </footer>
      <div class="copy container">
        <a rel="noopener" target="_blank" href="https://www.kareiki.com/">2021 © Tüm Hakları Saklıdır</a>
        <span><img src="@/assets/logo_band_white.svg" width="320"></span>
      </div>
    </div>
  </div>
</template>

<script>
import FixedNavbar from '@/components/FixedNavbar'

export default {
  components: { FixedNavbar },
  props: ['info', 'noNavBar']
}
</script>
<style scoped lang="scss">
.footerContainer{
  background:#222529;
  padding-top: 30px;
  padding-bottom: 30px;
}
.copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-top:1px solid #535353;
  margin-top: 20px;
  padding-top: 20px;
  a {
    color:#bbbbbb;
  }
  a:hover {
    color:#fff;
  }
  font-size: 13px;
}

footer {
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  gap:0px;
  .ft, .ftr {
    p {
      font-size: 14px;
      color: #e4e4e4;
      font-weight: bold;
      padding-bottom: 4px;
    }
  }
}
a {
  font-size: 13px;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  color:#bbbbbb;
}
a:hover {
  color:#fff;
}
@media screen and (max-width: 768px) {
  footer {
    grid-template-columns: repeat(2, 1fr);
    .ft {
      margin-bottom: 42px;
    }
  }
  .copy {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 50px;
    a {
      text-align: center;
    }
    img {
      width:calc(100% - 50px);
      margin-top: 16px;
    }
  }
}
</style>
